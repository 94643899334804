<template>
  <div class="vh-100 overflow-hidden">
    <!-- Header -->
    <div class="header bg-gradient-danger py-lg-5 log--header">
      <div class="separator separator-bottom separator-skew zindex-100 fill-default">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div
      class="sign--container position-absolute vw-100 vh-100 d-flex justify-content-around align-items-center overflow-auto"
    >
      <div class="d-flex flex-column justify-content-around align-items-center">
        <div class="d-flex align-items-center mb-2">
          <img src="/img/brand/wring.png" width="60" />
          <p class="my-4 ml-2 px-2 py-1 bg-primary d-inline-block rounded">
            <span class="text-white font-weight-bold h6 text-uppercase">Quikly</span>
          </p>
        </div>
        <div class="text-center">
          <p class="text-white h2 text-capitalize font-weight-bold">
            Intelligent Product Management
          </p>
          <p class="h4 mt--2 text-secondary font-italic font-weight-light">
            For smart automation behavior
          </p>
        </div>
        <div class="mt-3 p-5 rounded sign--form bg-white text-center" v-loading="loading">
          <h1 class="text-success">
            Congratulation!
            <i class="h1 fa fa-check-circle" aria-hidden="true"></i>
          </h1>
          <br />
          <p>
            We've sent an email to verify your account sign up. Please click on the link
            in that email to verify your account
          </p>
          <div class="text-center">
            <a href="/login" class="btn btn-primary my-2"> Log In </a>
          </div>
        </div>
        <div class="row mt-3 w-100">
          <div class="col-6">
            <a href="/password/reset" class="text-light"
              ><small>Forgot password?</small></a
            >
          </div>
          <div class="col-6 text-right">
            <a href="/register" class="text-light"><small>Create new account</small></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";

export default {
  mixins: [formMixin],
  data() {
    return {};
  },

  methods: {},
};
</script>
<style scoped lang="scss">
.login--container {
  height: 100vh;
  overflow: hidden;
}

.log--header {
  min-height: 50vh;
}
.sign--container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .sign--content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .sign--form i {
    color: green !important;
  }
}
</style>
